/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
		
		/*function checkboxToggle() {
			$('#telcheck, #datenschutz').on(
				'change', function() {
					$(this).parents('.checkbox-style').toggleClass('checked');
				}
			);

			$('.checkbox-style').on(
				'click', function() {
					$(this).find('input[type="checkbox"]').trigger('click');
				}
			);

			return this;
		}
		checkboxToggle();*/
		
		if ($('.post-type-archive-fg_standort').length) {
			
			$( "#plzsuche" ).submit(function( event ) {			  
			  event.preventDefault();
			});
			
			$( "#plzsuche .suchen" ).click(function(event) {
				event.preventDefault();
				console.log("click");
				
				var meineAdresseRaw = $('input[name="address"]').val();
				var meineAdresseURL = meineAdresseRaw.split(' ').join('+');
				console.log('meine Adresse URL: ' + meineAdresseURL + " --ENDE");
				$.getJSON('https://maps.googleapis.com/maps/api/geocode/json?address=' + meineAdresseURL + ',+Deutschland&key=AIzaSyDJ6-eOEZzVavlqDpMOCyBZOzkVO7avXzA', function (data) {
					lat = data.results[0].geometry.location.lat;
					lng = data.results[0].geometry.location.lng;

					$('input[name="lat"]').val(lat);
					$('input[name="lng"]').val(lng);

					document.getElementById("plzsuche").submit();
				});

				//console.debug( meineAdresse );
			});

			/*if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(showPosition);
			} 

			function showPosition(position) {
				$('input[name="address"]').val( position.coords.latitude + ',' + position.coords.longitude);
			}*/
			/*$(document).ready(function () {

			

				navigator.geolocation.getCurrentPosition(setgeoLoc);

			

				$('#distance').bind('change', function(){
					lat = $('input[name="lat"]').val();
					lng = $('input[name="lng"]').val();
					getdata(lat, lng);
				});
			
				$('.saveadd').bind('click', function(){
					address = $('input[name="address"]').val();
					setgeoLoc(false,address);
				});

			
				$('.reset').bind('click', function(){
					navigator.geolocation.getCurrentPosition(setgeoLoc);
				});
			});*/
		/*
			var geocoder;
			geocoder = new google.maps.Geocoder();

			function setgeoLoc(position,address) {
				if(address != undefined){
					geocoder.geocode( { 'address': address}, function(results, status) {
						if (status == google.maps.GeocoderStatus.OK) {
							$('.address').html(results[0].formatted_address);

							lat = results[0].geometry.location.Pa;
							lng = results[0].geometry.location.Qa;

							$('input[name="lat"]').val(lat);
							$('input[name="lng"]').val(lng);

							getdata(lat, lng);

						} else {
							alert("Geocoder failed due to: " + status);
						}
					});

				} else {

					lat = parseFloat(position.coords.latitude);
					lng = parseFloat(position.coords.longitude);

					latlng = new google.maps.LatLng(lat, lng);
					geocoder.geocode({'latLng': latlng}, function(results, status) {
					  if (status == google.maps.GeocoderStatus.OK) {
						if (results[0]) {
						  $('.address').html(results[0].formatted_address);
						  $('input[name="lat"]').val(lat);
						  $('input[name="lng"]').val(lng);

						  getdata(lat, lng);
						}
					  } else {
						alert("Geocoder failed due to: " + status);
					  }
					});
				}
			}

			function getdata(lat, lng) {
				var distance = $('#distance').val();
				distance = distance.substring(0,distance.length-1);
				var scriptPath = 'getdata.php?lat='+lat+'&lng='+lng+'&distance='+distance;

				$.ajax({
					type: 'GET',
					url: scriptPath,
					success: function(data) {
						$('.userdata').html(data);
					},
					error: function() {
						$('.userdata').html('</pre><div id="error">Verbindung zum Server fehlgeschlagen.</div><pre>');
					}
				});
			}
			*/
		}
		
		

      },
      finalize: function() {
        if ( $( ".testUmfrage" ).length ) {
							
			var totalItems = $('.testUmfrage .carousel-item').length - 1;
			var currentIndex = $('.testUmfrage .carousel-item.active').index() + 1;

			var down_index;
			
			var antworten = [];
			aaant = [];
			punkte = 0;
			
			$('.num span').html(''+currentIndex+' / '+totalItems+'');		
				
			
			/* jshint ignore:start */
			function carouselNormalization() {
			  var items = $('.testUmfrage .carousel-item .frage'), //grab all slides
				heights = [], //create empty array to store height values
				tallest; //create variable to make note of the tallest slide

			  if (items.length) {
				function normalizeHeights() {
				  items.each(function() { //add heights to array
					heights.push($(this).height());
				  });
				  tallest = Math.max.apply(null, heights); //cache largest value
				  items.each(function() {
					$(this).css('min-height', tallest + 'px');
				  });
				}
				normalizeHeights();

				$(window).on('resize orientationchange', function() {
				  tallest = 0, heights.length = 0; //reset vars
				  items.each(function() {
					$(this).css('min-height', '0'); //reset min-height
				  });
				  normalizeHeights(); //run it again 
				});
			  }
			}
			/* jshint ignore:end */
			carouselNormalization();								
			
			
			$('.testBtn').click(function(){
				
				var frg, ant, pkt, textF;
				frg = $(this).data('frage');
				ant = $(this).data('antwort');
				pkt = $(this).data('punkte');
				txt = $('#frageNr_' + frg).text().trim();
				
				antworten.push({'frage': frg, 'antwort': ant, 'punkte': pkt,'textF': txt});
				aaant[frg] = {'frage': frg, 'antwort': ant, 'punkte': pkt,'textF': txt};
														
				punkte += pkt;
				
				$('.testUmfrage').carousel('next');
				currentIndex_active = $('.testUmfrage .carousel-item.active').index() + 2;
				if (totalItems >= currentIndex_active)
				{
					down_index= $('.testUmfrage .carousel-item.active').index() + 2;
					$('.num span').html(''+currentIndex_active+' / '+totalItems+'');
				}
				
				
				
				if(currentIndex_active > totalItems){
					
					antworten.shift();
					aaant.shift();
					
					
					$('.num h5').html('Ergebnis');
					
					Number.prototype.between = function(a, b, inclusive) {
					  var min = Math.min(a, b),
						max = Math.max(a, b);

					  return inclusive ? this >= min && this <= max : this > min && this < max;
					};
						
						var l, p = 0;
						l = aaant.length;
						var htmlText = "";
						
						for (i = 0; i < l; i++) {
						
							p += aaant[i].punkte;													
							htmlText += 'Frage' + aaant[i].frage + ': '+ aaant[i].antwort + ' (' + aaant[i].punkte + ')\r\n' + aaant[i].textF + '\r\n\r\n';
																			
						}												
																		
					
					$( ".ergebnis" ).each(function( index ) {
						var v = $(this).data('von');
						var b = $(this).data('bis');					
																
						
						if(p.between(v, b, true)){
							$(this).removeClass('d-none');
							$(this).addClass('ergebnisAusgewaehlt');												
						}
					  
					});
					
					var finaltext = $('.ergebnisAusgewaehlt').text();
					
					$('#ergebnissausgewahlt').val(finaltext);
					$('#auswertung').val(htmlText);
					$('#punkte').val(p);
					$('.secTestFormular').removeClass('d-none');
				}
				
			});
		}
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
